/* eslint-disable prettier/prettier */
'use client';

import { useEffect, useState } from 'react';
import { hideDynamicModal } from '@next-app/utils/common-utils';
import Button from '../elements/Button/Button';
import Conditional from '../Conditional';
import DynamicModal from '../elements/DynamicModal/DynamicModal';
import {
  getFromSessionStorage,
  removeFromSessionStorage,
} from '@next-app/utils/session-storage-utils';
import classes from './SessionExpiryModal.module.scss';

const SessionExpiryModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isSessionExpired = getFromSessionStorage('isSessionExpired') === 'true';
  const sessionExpiredInfo = getFromSessionStorage('llSessionExpiredInfo');

  const handleClose = () => {
    setIsOpen(false);
    hideDynamicModal();
  };

  useEffect(() => {
    if (isSessionExpired) {
      setIsOpen(true);
      const modalElement = document?.getElementById(
        'dynamicModalWrapper',
      ) as HTMLElement;
      if (modalElement) {
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.show();
        removeFromSessionStorage('isSessionExpired');
      }
    }
  }, [isSessionExpired]);

  return (
    <Conditional if={isOpen}>
      <DynamicModal
        modalContentClass={`${classes['gsa-redirect-modal']}`}
        modalTitle="Session Expired"
        onModalClose={handleClose}
      >
        <div className="mb-3">
          <p>{sessionExpiredInfo}</p>
        </div>
        <div className="text-center">
          <Button
            type="button"
            variant="primary"
            data-bs-dismiss="modal"
            data-dismiss="dynamicModalWrapper"
            onClick={handleClose}
          >
            Continue
          </Button>
        </div>
      </DynamicModal>
    </Conditional>
  );
};

export default SessionExpiryModal;
